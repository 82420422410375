import React from 'react';
import './App.css';
import Footer from './components/footer';
function App() {
  return (
    <div className='main-container'>
        <header className='App-header'>
            <h1>Welcome to th1rd.net</h1>
            <p>Your one-stop solution for all your needs!</p>
        </header>
        <main>
            <p>This is a simple React application.</p>
            <p>Feel free to explore and customize it as per your requirements.</p>
        </main>
        <footer className='App-footer'>
            <p>&copy; 2023 th1rd.net. All rights reserved.</p>
        </footer>
        <Footer />
    </div>
  );
}

export default App;
